<template>
  <div class="relative block">
    <transition mode="out-in" name="fade">
      <throbber
        v-if="isLoading"
        class="z-10"
        :color="this.secondary ? 'dark' : ''"
      />
    </transition>
    <button
      type="submit"
      :disabled="!isEnabled || isLoading"
      ref="submitBtn"
      class="text-center px-3 py-2 rounded bg-indigo-500 text-white hover:bg-indigo-600 cursor-pointer"
      :data-tippy-content="tooltip"
      :class="{
        'opacity-25 cursor-not-allowed pointer-events-none':
          !isEnabled || isLoading,
        'w-full text-center': isFull,
        'btn-secondary': secondary,
        'btn-warning': warning,
        'btn-caution': caution,
        'is-loading': isLoading,
        'py-4': large,
        'h-full': stretch,
        [uniqKey]: tooltip
      }"
    >
      <div
        class="flex items-center text-center default-animation"
        :class="{ 'opacity-0': isLoading }"
      >
        <slot></slot>
        <div class="w-full" v-if="label">
          {{ label }}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import Throbber from '@/components/common/ui/Throbber';
import tippy from 'tippy.js';
import utils from '@/utils';
export default {
  components: {
    Throbber
  },
  props: {
    isLoading: Boolean,
    isEnabled: {
      type: Boolean,
      default: true
    },
    label: String,
    secondary: Boolean,
    warning: Boolean,
    isFull: Boolean,
    large: Boolean,
    stretch: Boolean,
    tooltip: String,
    caution: Boolean
  },
  data() {
    return {
      uniqKey: utils.createKey('loading-button', 6)
    };
  },
  mounted() {
    if (this.tooltip) {
      this.$nextTick(() => {
        tippy(`.${this.uniqKey}`, {
          arrow: false,
          delay: [400, 0],
          touch: 'hold'
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.throbber {
  @apply absolute left-0 right-0;
}

.btn-disabled {
  opacity: 0.5;
}

.btn-secondary {
  @apply bg-gray-200 text-gray-800;
  &:hover {
    @apply bg-gray-300;
  }
  &.is-loading {
    @apply bg-gray-300;
  }
}
.btn-warning {
  @apply bg-red-100 text-red-500;
  &:hover {
    @apply bg-red-200;
  }
  &.is-loading {
    @apply bg-red-200;
  }
}
.btn-caution {
  @apply bg-yellow-100 text-yellow-500;
  &:hover {
    @apply bg-yellow-200;
  }
  &.is-loading {
    @apply bg-yellow-200;
  }
}
</style>
