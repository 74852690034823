<template>
  <div class="px-4 sm:px-0">
    <div
      class="w-full sm:w-96 py-16 sm:py-0 mx-auto flex  flex-col sm:justify-center items-center h-full"
    >
      <form class="w-full" @submit.prevent="login" novalidate="true">
        <div
          v-if="hasError"
          class=" bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
        >
          The email address or password you entered is incorrect.
        </div>
        <div class="input-group">
          <input
            v-model="loginCredentials.email"
            required
            ref="email"
            type="text"
            placeholder="Email"
          />
        </div>
        <div class="input-group flex items-center relative  ">
          <input
            v-model="loginCredentials.password"
            required
            :type="hidePassword ? 'password' : 'text'"
            placeholder="Password"
            ref="password"
          />
          <div class="absolute right-3" @click="toggleView">
            <font-awesome-icon
              class="text-lg text-gray-400 hover:text-gray-900 cursor-pointer "
              :icon="['fal', hidePassword ? 'eye' : 'eye-slash']"
            />
          </div>
        </div>

        <loading-button
          :is-loading="isLoading"
          :is-enabled="formValid"
          :is-full="true"
          label="Login"
        />
      </form>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import LoadingButton from '@/components/common/ui/LoadingButton';

export default {
  metaInfo: {
    title: 'Login'
  },
  data() {
    return {
      loginCredentials: {
        email: '',
        password: ''
      },
      hasError: false,
      isLoading: false,
      hidePassword: true
    };
  },
  components: {
    LoadingButton
  },
  computed: {
    formValid() {
      let self = this;
      return Object.keys(this.loginCredentials).every(field => {
        return self.loginCredentials[field] && self.loginCredentials[field];
      });
    }
  },
  methods: {
    login: function() {
      this.isLoading = true;
      let email = this.loginCredentials.email;
      let password = this.loginCredentials.password;
      let self = this;

      store
        .dispatch('login', { email, password })
        .then(() => {
          store.dispatch('getLoggedInUser').then(function() {
            self.$store.dispatch('getKey', 'cert');
            self.$store.dispatch('getKey', 'private');
            self.$mixpanel.track('isLoggedIn');
            self.hasError = false;
            self.$router.push({ name: 'dashboard' }).catch(err => {
              console.log(err);
            });
          });
        })
        .catch(err => {
          self.isLoading = false;
          this.hasError = true;
          throw Error(err.response.data.error.message);
        });
    },
    toggleView() {
      this.hidePassword = !this.hidePassword;
    }
  },
  mounted() {
    if (this.$route.params.email) {
      this.$refs.password.focus();
    } else {
      this.$refs.email.focus();
    }
  }
};
</script>

<style lang="scss" scoped></style>
