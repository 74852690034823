var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative block"},[_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[(_vm.isLoading)?_c('throbber',{staticClass:"z-10",attrs:{"color":this.secondary ? 'dark' : ''}}):_vm._e()],1),_c('button',{ref:"submitBtn",staticClass:"text-center px-3 py-2 rounded bg-indigo-500 text-white hover:bg-indigo-600 cursor-pointer",class:{
      'opacity-25 cursor-not-allowed pointer-events-none':
        !_vm.isEnabled || _vm.isLoading,
      'w-full text-center': _vm.isFull,
      'btn-secondary': _vm.secondary,
      'btn-warning': _vm.warning,
      'btn-caution': _vm.caution,
      'is-loading': _vm.isLoading,
      'py-4': _vm.large,
      'h-full': _vm.stretch,
      [_vm.uniqKey]: _vm.tooltip
    },attrs:{"type":"submit","disabled":!_vm.isEnabled || _vm.isLoading,"data-tippy-content":_vm.tooltip}},[_c('div',{staticClass:"flex items-center text-center default-animation",class:{ 'opacity-0': _vm.isLoading }},[_vm._t("default"),(_vm.label)?_c('div',{staticClass:"w-full"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }