<template>
  <div
    class="throbber flex h-full items-center justify-center"
    :class="[hasSize, hasColor]"
  >
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
</template>

<script>
export default {
  props: ["size", "color"],
  computed: {
    hasSize: function() {
      return "size-" + this.size;
    },
    hasColor: function() {
      return "color-" + this.color;
    }
  }
};
</script>

<style lang="scss" scoped>
.throbber {
  .ball {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 4px;
    @apply bg-white;
    border-radius: 8px;
    -webkit-animation: action 0.7s infinite;

    &:last-child {
      margin-right: 0px;
    }

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }

  &.size-lg {
    .ball {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }

  &.color-dark {
    .ball {
      @apply bg-gray-300;
    }
  }
  &.color-light {
    .ball {
      @apply bg-gray-300;
    }
  }
}

@-webkit-keyframes action {
  0% {
    transform: translateY(4px);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.6;
  }
  100% {
    transform: translateY(4px);
    opacity: 1;
  }
}
</style>
